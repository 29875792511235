.SidebarContent {
  background-color: white;
  min-height: 100%;
}
.SidebarContent .Title {
  background-color: blue;
  padding: 10px;
  color: white;
  width: 200px;
}
.SidebarContent .RouteItem {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}
.SidebarContent .RouteItem.On {
  background-color: green;
}
.SidebarContent .RouteItem:hover {
  background-color: #ddd;
}
.SidebarContent .SearchIcon {
  padding: 10px;
}
